import BackendService from './BackendService';
const singleName = 'landing';
const pluralName = 'landings';

export default class LandingService extends BackendService {

    constructor() {
        super();
    }
    create(data) {
        return this.httpCall(singleName,{
            method: 'POST',
            data: data,
        });

    }
    createFileImage(data, urlType) {
        return this.httpCall(singleName+urlType,{
            method: 'POST',
            data: data,
            headers: { 'Content-Type': 'multipart/form-data' },
        });

    }
    deleteFileImage(pathFile) {
        return this.httpCall(singleName+'-remove-file',{
            method: 'POST',
            data: {
                pathFile: pathFile            
            }
        });
    }



    update(data) {
       //console.log('contrato, con: ', contract)
        return this.httpCall(singleName+'/'+data._id,{
            method: 'PUT',
            data: data,
        });
    }
    toggleStatus(_id){
        return this.httpCall(singleName+'/'+_id+'/toggle',{
            method: 'PUT',
        });
    }

    deletet(rateConfig_id) {
        return this.httpCall(singleName+'/'+rateConfig_id,{
            method: 'delete'
        });
    }

    getById(rule_id) {

        return this.httpCall(singleName+'/'+rule_id);

        //return this.httpCall('property' + property_id + '/users');
    }

    getList(params) {
        //console.log('get rooms');

        return this.httpCall(pluralName,{
            params: params,
        });
    }

}
