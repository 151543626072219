<template>
    <div class="container-fluid"  @click="close">
        <div class="row">
            <div class="col-md-12 ">
                <h4 class="title-in-h">{{info_page.header}}</h4>
                <breadcrumb>
                    <breadcrumb-item v-for="breadcrumb in breadcrumbs" v-if="breadcrumb.type!=='active'">
                        <router-link :to="{path:breadcrumb.path}" class="color-blue-var">{{breadcrumb.name}}</router-link>
                    </breadcrumb-item>
                    <breadcrumb-item active v-else>
                        {{breadcrumb.name}}
                    </breadcrumb-item>
                </breadcrumb>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <card  v-loading="screenLoading"
                       element-loading-lock="true"
                       :element-loading-text="screenText"
                       element-loading-customClass="loading-large"
                       element-loading-spinner="el-icon-loading">
                    <div>
                        <div class="col-sm-12">
                            <div class="box-inventory landings">
                                <div class="form-updates w-border" >
                                    <div class="row">
                                        <div class="col-md-11">
                                            <div class="head  mt-3 mb-0">
                                                <label class="subtitle mb-0">Información general</label>
                                            </div>
                                            <div class="row">
                                                <div class="col-6">
                                                    <div class="group-item  custom-error mbis-0 mt-3">
                                                        <label>{{$t('landing.form.vigency')}}</label>
                                                        <fg-input style="width: 100%" >
                                                            <el-date-picker v-model="landing.vigency" type="daterange"
                                                                            format="dd-MM-yyyy"
                                                                            :picker-options="pickerOptions1"
                                                                            range-separator="a"
                                                                            start-placeholder="Fecha inicio"
                                                                            end-placeholder="Fecha fin"
                                                                            value-format="yyyy-MM-dd"
                                                                            :default-time="['00:00:00', '00:00:00']"
                                                            >
                                                            </el-date-picker>
                                                        </fg-input>
                                                        <label class="text-danger" v-if="errores.vigency">El campo Vigencia es requerido</label>
                                                    </div>
                                                </div>
                                                <div class="col-4">
                                                    <div class="group-item box-colors mbis-0 mt-3">
                                                        <label>{{$t('landing.form.text_color')}}</label>
                                                        
                                                        <div class="input-group color-picker-component bx-color mb-2"   @click.stop>
                                                        <input type="text" v-model="landing.text_color" class=" inp-color"
                                                               @focus="openPicker()"
                                                               @input="landing.text_color = landing.text_color.toUpperCase()">
                                                        <span class="current-color"
                                                              :style="'background-color: ' + landing.text_color"
                                                              @click="openPicker()" ></span>

                                                        <span class="picker ">
                                                            <chrome-picker  @input="updateFromPicker(landing.text_color)" v-model="landing.text_color"   v-if="info_page.color_picker"></chrome-picker>
                                                        </span>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="group-item  mbis-0 mt-3">
                                                        <label>{{$t('landing.form.image')}}</label>
                                                        <el-upload
                                                                class="upload-demo"
                                                                drag
                                                                action="#"
                                                                accept="image/jpeg,image/gif,image/png"
                                                                :on-preview="handlePictureCardPreview"
                                                                :auto-upload="true"
                                                                :http-request="addImage"
                                                                :on-remove="handleRemove"
                                                                :show-file-list="false"
                                                                :file-list="landing.image">
                                                            <img v-if="dialogImageUrl" :src="dialogImageUrl" class="avatar">
                                                            <i class="el-icon-upload" v-if="!dialogImageUrl"></i>
                                                            <div class="el-upload__text" v-if="!dialogImageUrl">Suelta tu imagen aquí o <em>haz clic para cargar</em></div>
                                                            <div slot="tip" class="el-upload__tip mt-0"> <label class="text-danger" v-if="errores.image">El campo Imagen es requerido</label></div>                                                            
                                                            <div slot="tip" class="el-upload__tip"><i class="fa fa-exclamation-circle"></i>Solo imagenes jpg/png; con dimensiones 1100px / 400px con un tamaño menor de 1MB</div>
                                                        </el-upload>
                                                    </div>
                                                </div>
                                                <div class="col-6">
                                                    <div class="group-item  mbis-0 mt-3">
                                                        <label>{{$t('landing.form.image_movil')}}</label>
                                                        <el-upload
                                                                class="upload-demo upload-movil"
                                                                drag
                                                                action="#"
                                                                accept="image/jpeg,image/gif,image/png"
                                                                :on-preview="handlePictureCardPreview"
                                                                :auto-upload="true"
                                                                :http-request="addImageMovil"
                                                                :on-remove="handleRemoveMovil"
                                                                :show-file-list="false"
                                                                :file-list="landing.image_movil">
                                                            <img v-if="dialogImageMovilUrl" :src="dialogImageMovilUrl" class="avatar">
                                                            <i class="el-icon-upload" v-if="!dialogImageMovilUrl"></i>
                                                            <div class="el-upload__text" v-if="!dialogImageMovilUrl">Suelta tu imagen aquí o <em>haz clic para cargar</em></div>
                                                            <div slot="tip" class="el-upload__tip"><i class="fa fa-exclamation-circle"></i>Solo imagenes jpg/png; con dimensiones 330px / 400px con un tamaño menor de 1MB</div>
                                                        </el-upload>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                           

                                            


                                            <el-tabs v-model="info_page.tab_lang">




                                                <el-tab-pane :label="itemLang.text" :name="itemLang.lang" v-for="(itemLang, keyLang ) in info_page.langs">
                                                  
                                                    <div class="group-item mt-3">
                                                        <label>{{$t('landing.form.name')}}</label>
                                                        <fg-input type="text"
                                                                  style="width: 100%"
                                                                  placeholder=""
                                                                  v-validate="modelValidations.name && itemLang.lang==='es'"
                                                                  :error="getError($t(info_page.lang_file+'.form.name')+' '+itemLang.lang)"
                                                                  :name="$t(info_page.lang_file+'.form.name')+' '+itemLang.lang"
                                                                  v-model="landing.name[keyLang].text">
                                                        </fg-input>

                                                        <div slot="tip" class="el-upload__tip" v-if="info_page.action==='edit' && landing.name_original[keyLang].text!==landing.name[keyLang].text"><i class="fa fa-exclamation-circle"></i>Considera que al modificar el nombre también  se actualizará la ruta de acceso para el Landing</div>



                                                    </div>
                                                    <div class="group-item mt-3">
                                                        <label>{{$t('landing.form.title_banner')}}</label>
                                                        <fg-input type="text"
                                                                  style="width: 100%"
                                                                  placeholder=""
                                                                  v-validate="modelValidations.title_banner  && itemLang.lang==='es'"
                                                                  :error="getError($t(info_page.lang_file+'.form.title_banner')+' '+itemLang.lang)"
                                                                  :name="$t(info_page.lang_file+'.form.title_banner')+' '+itemLang.lang"
                                                                  v-model="landing.title_banner[keyLang].text">
                                                        </fg-input>
                                                    </div>
                                                    <div class="group-item mt-3">
                                                        <label>{{$t('landing.form.text_banner')}}</label>
                                                        <fg-input type="text"
                                                                  style="width: 100%"
                                                                  placeholder=""
                                                                  :name="$t(info_page.lang_file+'.form.text_banner')+' '+itemLang.lang"
                                                                  v-model="landing.text_banner[keyLang].text">
                                                        </fg-input>

                                                    </div>
                                                    <div class="group-item mt-3">
                                                        <label>{{$t('landing.form.title_content')}}</label>
                                                        <fg-input type="text"
                                                                  style="width: 100%"
                                                                  placeholder=""
                                                                  v-validate="modelValidations.title_content && itemLang.lang==='es'"
                                                                  :error="getError($t(info_page.lang_file+'.form.title_content')+' '+itemLang.lang)"
                                                                  :name="$t(info_page.lang_file+'.form.title_content')+' '+itemLang.lang"
                                                                  v-model="landing.title_content[keyLang].text">
                                                        </fg-input>
                                                    </div>
                                                    <div class="group-item mt-3">
                                                        <label>{{$t('landing.form.content')}}</label>

                                                        <tinymce-editor :api-key="apiKey" v-model="landing.content[keyLang].text" :init="{height: 250, plugins: ['autolink lists link charmap',
           'searchreplace  fullscreen paste wordcount'],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat '}"  > </tinymce-editor>
                                                        <label class="text-danger" v-if="errores.content  && itemLang.lang==='es'">El campo Contenido es requerido</label>
                                                    </div>

                                                    <div class="group-item mt-3">
                                                        <label>{{$t('landing.form.title_gratitude')}}</label>
                                                        <fg-input type="text"
                                                                  style="width: 100%"
                                                                  placeholder=""
                                                                  :name="$t(info_page.lang_file+'.form.title_gratitude')+' '+itemLang.lang"
                                                                  v-model="landing.title_gratitude[keyLang].text">
                                                        </fg-input>
                                                    </div>

                                                    <div class="group-item  custom-error mt-3">
                                                        <label>{{$t('landing.form.gratitude')}}</label>
                                                        <tinymce-editor :api-key="apiKey" v-model="landing.gratitude[keyLang].text" :init="{height: 250, plugins: ['autolink lists link charmap',
           'searchreplace  fullscreen paste wordcount'],
         toolbar:
           'undo redo | formatselect | bold italic backcolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | removeformat '}"  > </tinymce-editor>
                                                    </div>
                                                </el-tab-pane>
                                            </el-tabs>







                                            <div class="group-item mt-3">
                                                <label>{{$t('landing.form.file')}}</label>
                                                <el-upload
                                                        class="upload-demo"
                                                        drag
                                                        action="#"
                                                        accept="image/jpeg,image/gif,image/png,.pdf"
                                                        :auto-upload="true"
                                                        :http-request="addFile"
                                                        :on-remove="handleRemove"
                                                        :file-list="landing.file">
                                                    <i class="el-icon-upload"></i>
                                                    <div class="el-upload__text">Suelta tu archivo aquí o <em>haz clic para cargar</em></div>
                                                    <div slot="tip" class="el-upload__tip">Solo archivos pdf/jpg/png con un tamaño menor de 500kb</div>
                                                </el-upload>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-md-10">
                                            <div class="head  mt-3 ">
                                                <label class="subtitle mb-0">Contratos:</label>
                                            </div>
                                            <div class="box-rates">

                                                <div class="group-item custom-error mbis-0">

                                                    <div class="form-check mb-0"  v-for="rateplan in info_page.rateplans">
                                                        <label :for="rateplan.rateconfig_id" class="form-check-label">
                                                            <input class="form-check-input" type="checkbox" :id="rateplan._id"
                                                                   :value="rateplan._id" v-model="rateplan.checked">
                                                            <!--@change="stepsValidate('type')" >-->
                                                            <span class="form-check-sign"></span>{{rateplan.name}}
                                                        </label>
                                                    </div>
                                                </div>


                                                <label class="text-danger" v-if="errores.rateplans">Es necesario agregar un contrato</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <div class="form-send" v-show="info_page.index_page===1">
                                    <div class="row">
                                        <div class="col-md-12 mt-3 mb-2">
                                            <div class="div">
                                                <span class="text-danger fllw" v-for="error_is in errores_custom" v-text="'*'+error_is"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="box-button mb-4">                                      
                                        <button type="button" class="add-item btn-success"  @click="sendForm(info_page.action)" > {{info_page.button}}</button>
                                        <button type="button" class="add-item second btn-danger"  @click="cancelAction()">Cancelar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </card>
            </div>
        </div>
    </div>

</template>
<script>
    import swal from 'sweetalert2'
    import {Breadcrumb, BreadcrumbItem, Radio, Switch as LSwitch} from 'src/components/index'
    import {Select, Option, DatePicker, TimeSelect, TimePicker, Input,  Upload,  TabPane, Tabs, Switch, Dialog} from 'element-ui'
    import Editor from '@tinymce/tinymce-vue';
    import { Chrome } from 'vue-color'
    //import users from 'src/pages/Dashboard/Tables/users'
    import moment from 'moment';
    import PropertyService from '../../../js/services/PropertyService';
    import RoomService from '../../../js/services/RoomService';
    import RateService from '../../../js/services/RateService';
    import PromotionService from '../../../js/services/PromotionService';
    import LandingService from '../../../js/services/LandingService';
    import RateplanFunctions from '../../../js/functions/RateplanFunctions';
    import LogFunctions from '../../../js/functions/LogFunctions';
    import LogService from '../../../js/services/LogService';

    import Settings from "src/js/helpers/Settings";
    let settings = new Settings();

    import EventBus from "../../../js/helpers/EventBus";
    import {vueTopprogress} from 'vue-top-progress'
    import Loading from 'src/pages/Dashboard/Layout/LoadingMainPanel.vue'

    import ContractService from '../../../js/services/ContractService';
    const landingService = new LandingService();
    const contractService = new ContractService();
    const rateService = new RateService();
    const promotionService = new PromotionService();
    const propertyService = new PropertyService();
    const roomService = new RoomService();
    const rateplanFuntions = new RateplanFunctions;
    const logFunctions = new LogFunctions();
    const logService = new LogService();

    import {mapGetters} from "vuex";
    import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'
    import ElCollapseTransition from "element-ui/src/transitions/collapse-transition";

    export default {
        components: {
            ElCollapseTransition,
            [CollapseTransition.name]: CollapseTransition,
            [DatePicker.name]: DatePicker,
            [TimeSelect.name]: TimeSelect,
            [TimePicker.name]: TimePicker,
            [Upload.name]: Upload,
            [Dialog.name]: Dialog,
            'tinymce-editor': Editor,
            'chrome-picker': Chrome,
            Breadcrumb,
            BreadcrumbItem,
            vueTopprogress,
            [Tabs.name]: Tabs,
            [Switch.name]: Switch,
            [TabPane.name]: TabPane,
            [Option.name]: Option,
            [Select.name]: Select,
            LSwitch,
            Radio
        },
        computed: {
            ...mapGetters(["currentUser","selected_property"]),
        },
        data() {
            return {
                log_page:{
                    action: null,
                    section: null,
                    property_id: null,
                    data: [],
                    date: new Date(),
                    object_id: null,
                    user_id: null,
                },
                apiKey: 'kdft9s69zgphk86767gp0b10ykt0iljm2m8a5o9zy6wcu03k',
                activeErrors: false,
                screenLoading: false,
                errores_custom: [],
                formImcompleted: false,
                dialogImageUrl: '',
                dialogImageMovilUrl: '',
                dialogVisible: false,
                breadcrumbs: [
                    {
                        name: this.$t('property.index.home'),
                        path: '/admin/properties',
                        type: '',
                    },
                    {
                        name: this.$t('landing.index.title'),
                        path: '/admin/landings',
                        type: '',
                    },

                    {
                        name: this.$t('landing.new.title'),
                        path: '',
                        type: 'active',
                    }
                ],
                info_page: {
                    original_info:{},
                    section_name: 'landing',
                    property: null,
                    rateplans: [],
                    jrs: false,
                    children: false,
                    action: 'add',
                    header: this.$t('landing.new.header'),
                    button:  this.$t('landing.new.button'),
                    property_id: {},
                    index_page: 1,
                    color_picker: false,
                    buttons: {
                        information: false,
                        rateplans: false,
                        name: false,
                    },
                    visible:{
                        information: false,
                        rateplans: false,
                        name: false,
                    },


                    tab_lang: 'es',
                    langs: [
                        {
                            lang: 'es',
                            text: this.$t('locale_es'),
                        },
                        {
                            lang: 'en',
                            text: this.$t('locale_en')
                        }

                    ],
                    lang_file: 'landing',
                    response_name: 'landings',
                    multi_langs: ['name','title_banner', 'text_banner', 'title_content', 'content', 'title_gratitude', 'gratitude']
                },

                landing: {
                    _id: null,
                    name: [],
                    name_original: [],
                    vigency:  [],

                    title_banner: [],
                    text_banner:[],
                    title_content: [],
                    content: [],
                    title_gratitude: [],
                    gratitude: [],


                    /*title_banner: null,
                    text_banner: null,
                    title_content: null,
                    content: null,
                    title_gratitude: null,
                    gratitude: null,*/
                    image: [],
                    file: [],
                    image_movil: [],
                    text_color: '#ebebeb',
                    rateplans: [],
                    all_rate_configs: true,
                    status: true,


                },

                pickerOptions1: {
                    disabledDate: time => {
                        var d = new Date();
                        d.setDate(d.getDate() - 1);
                        //console.log('day', d)
                        return time <= d.getTime()
                    }

                },
                errores: {
                    content: false,
                    gratitude: false,
                    image: false,
                    vigency: false,
                    rateplans: false
                },
                modelValidations: {
                    name: {
                        required: true
                    },
                    title_banner: {
                        required: true
                    },
                    text_banner: {
                        required: true
                    },
                    title_content: {
                        required: true
                    },
                    content: {
                        required: true
                    },
                    title_gratitude: {
                        required: true
                    },
                    gratitude: {
                        required: true
                    },
                },

            }
        },
        created() {
            this.chargerItems(this.selected_property._id);
            moment.locale('es');
        },
        methods: {
            changeProperty(){
                EventBus.$on('CHANGE_PROPERTY', (property_id) => {
                    if(this.$route.name==='editPromotion'){
                        this.$router.push({
                            name: 'Promotions'
                        });
                    }if(this.$route.name==='CreatePromotion'){
                        this.chargerItems(property_id)
                    }
                })
            },

            async getInfoProperty(property_id){
                //console.log('this.selected_property', this.selected_property);
                this.info_page.property = this.selected_property;
                //propertyResponse = propertyResponse.data.data.property;
                //return propertyResponse.data.data.property;age_jr
            },

            async chargerItems(property_id = null) {
                this.info_page.property_id = property_id;
                this.info_page.index_page = 1;
                this.initLoading();
                this.getInfoProperty(property_id)
                let date_ = moment().locale('es');
                this.landing.property_id = property_id;
                this.configLogs(property_id, this.info_page.section_name);
                let fieldsArray = this.info_page.multi_langs;
                fieldsArray.forEach((itemField, keyField ) => {
                    this.info_page.langs.forEach((itemLang, keyLang ) => {
                        this.landing[itemField].push({
                            lang: itemLang.lang,
                            text: '',
                        })
                    })
                });

                await this.chargeRateplans(property_id);

                if(this.$route.name==='editLanding'){
                    let landing_id = this.$route.params.id;
                    this.info_page.action = 'edit';
                    this.log_page.action = 1;
                    this.info_page.header = this.$t('landing.edit.header');
                    this.info_page.button = this.$t('landing.edit.button');
                    this.breadcrumbs[this.breadcrumbs.length-1].name = this.$t('landing.edit.header');
                    this.updateData(landing_id);
                }else{
                    setTimeout(()=>{
                        this.closeLoading();
                    }, 500)
                }

            },
            configLogs(property_id, section_name){
                this.log_page.section = logFunctions.findSection(section_name)._id
                this.log_page.property_id = property_id;
                this.log_page.user_id = this.currentUser._id;
                this.log_page.action = 0;
                this.log_page.date = moment().format('YYYY-MM-DD HH:mm');
            },
            async createLog(object_id, action, data = []){
                let paramsLog= this.log_page;
                paramsLog.object_id = object_id;
                //paramsLog.section = logFunctions.findSection(this.info_page.section_name)._id
                paramsLog.action = action;
                paramsLog.data = data;
                paramsLog.date = moment().format('YYYY-MM-DD HH:mm:ss');
                await logService.create(paramsLog);
            },
            async updateData(elementID){
                let objectInfo = await landingService.getById(elementID);
                let data = objectInfo.data.data.landing;
                this.info_page.original_info = JSON.parse(JSON.stringify(data))
                let fieldsArray = this.info_page.multi_langs;
                //console.log('data', data);
                if (data.from !== null) {
                    data.from = moment(data.from, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
                    data.to = moment(data.to, 'YYYY-MM-DD HH:mm').format('YYYY-MM-DD')
                    data.vigency = [data.from, data.to]
                    delete data.from;
                    delete data.to;
                }
                for (let prop in data) {


                    if(prop === 'image' || prop ==='file' || prop ==='image_movil'){
                        if(data[prop]!==null){
                            this.landing[prop].push({
                                _id: data[prop],
                                name: data[prop],
                                url: data[prop],
                                onServer: true
                            })
                        }

                        if(prop === 'image' ){
                            this.dialogImageUrl = data[prop];
                        }
                        if(prop === 'image_movil' ){
                            this.dialogImageMovilUrl = data[prop];
                        }

                    }else if(prop === 'rateplans'){
                        this.info_page.rateplans.map(item => item.checked = data.rateplans.some( element => item._id === element))
                    }else{

                        if(fieldsArray.some(item=> item === prop)){
                            if(Array.isArray(data[prop])){
                                this.landing[prop].map(item => item.text = data[prop].find(element => element.lang === item.lang).text)
                            }else{

                                let objectBase = this.landing[prop].find(item=> item.lang === 'es');
                                objectBase.text = data[prop];
                            }
                            if(prop === 'name' ){
                                this.landing.name_original = JSON.parse(JSON.stringify(this.landing.name));
                            }
                        }else{
                            this.landing[prop] = data[prop];

                        }
                    }
                }

                //this.landing._id = elementID;
                this.closeLoading();
            },




            async chargeRateplans(property_id){

                let rateConfigs = this.landing.rateplans;
                let params = {
                    property_id: property_id,
                    status: true,
                    expired: false
                }
                let rateplans =  await contractService.getRateplanIndex(params);
                rateplans = rateplans.data.data.rateplans;
                rateplans.map(item => item.checked = false)
                this.info_page.rateplans = rateplans;
            },

            openPicker(){
                this.info_page.color_picker = true;
            },
            updateFromPicker(item) {
                this.landing.text_color = item.hex;
            },

            close(e) {
                this.info_page.color_picker = false;
            },


            createObjectQuick(value, text, type, min_value = 0){
                return  {
                    value,
                    text,
                    type,
                    min_value
                }
            },

            validadateForm(){
                let arrayRespuesta = [];
                let arrayToValidate = [];
                let errorResponse = false;
                arrayRespuesta = this.fieldsValidate(arrayToValidate);
                this.errores_custom = arrayRespuesta.fields;
                return !arrayRespuesta.status;
            },


            fieldsValidate(fields){
                let booleanStatus = false;
                let arrayResponses = [];
                fields.forEach(field_info=>{
                    let field = field_info.value;
                    let text = field_info.text;
                    let type = field_info.type;
                    let min_value = field_info.min_value;
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                            arrayResponses.push('El campo '+text+' es requerido');
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                            arrayResponses.push('El campo '+text+' es requerido');
                        }
                        if(parseFloat(field)<min_value){
                            booleanStatus = true ;
                            arrayResponses.push('La cantidad  mínima requerida para '+text+' es: '+min_value );
                        }
                    }
                    if(type==='array'){

                        if(field.length===0){
                            arrayResponses.push('Es necesario agregar un valor agregado');
                            return;
                        }

                        field.forEach(item=>{
                            field = field+'';
                            if(item.name==='null' ||  item.name==='' || item.name.trim().length === 0 ||
                                item.text_es==='null' ||  item.text_es==='' || item.text_es.trim().length === 0  ||
                                item.text_en==='null' ||  item.text_en==='' || item.text_en.trim().length === 0  ){
                                booleanStatus = true ;
                                let checkIdx = arrayResponses.findIndex(itemT=> itemT === 'Es necesario completar todos los campos de los valores agregados')

                                console.log(checkIdx, arrayResponses);

                                if(checkIdx<0){
                                    arrayResponses.push('Es necesario completar todos los campos de los valores agregados');
                                }

                                return;
                            }
                        })

                    }
                    if(type==='min_than'){
                        //console.log(parseInt(field), min_value, 'min_than');
                        if(parseInt(field)<=min_value){
                            booleanStatus = true ;
                            arrayResponses.push(text);
                        }
                    }
                    if(type==='min_time'){
                        if(field<=min_value){
                            booleanStatus = true ;
                            arrayResponses.push(text);
                        }
                    }

                });

                return {
                    status: booleanStatus,
                    fields: arrayResponses
                };

            },

            async  sendInfo(){
                if(!this.validadateForm()){
                    return
                }
            },
            cancelAction(){
                this.$router.push({
                    name: 'Landings'
                });
            },

            validadorCustom(fields, type){
                let booleanStatus = false;
                fields.forEach(field=>{
                    if(type === 'string'){
                        field = field+'';
                        if(field==='null' ||  field==='' || field.trim().length === 0 ){
                            booleanStatus = true ;
                        }
                    }
                    if(type==='number'){
                        if(isNaN(parseFloat(field)) || !isFinite(field)){
                            booleanStatus = true ;
                        }
                    }
                });

                return booleanStatus;

            },
            formatConverte(date, formatType = null) {
                let date_return = null;

                switch (formatType) {
                    case 'f-month':

                        date_return  =  moment(date, 'YYYY-MM-DD').format('MMMM D[,] YYYY')
                        break;
                    case 'txt':
                        date_return  =  moment(date, 'YYYY-MM-DD').format('D [de] MMMM YYYY')
                        break;
                    case 'time':
                        date_return  =  moment(date).format('HH:mm')
                        break;

                    default:
                        date_return  =  moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY')
                        break;
                }
                return date_return;
            },
            showSwal (msg) {

                swal({
                    title: this.$t('landing.new.modal'),
                    text: msg,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill',
                    type: 'success'
                }).then(()=> {
                    this.$router.push({
                        name: 'Landings'
                    });
                });
            },
            initLoading(text = 'Cargando...') {
                this.screenLoading = true;
                this.screenText = text;
            },
            closeLoading(){
                this.screenLoading = false;
            },
            alertError(title_cust, text_cust){


                swal({
                    title: title_cust,
                    type: 'warning',
                    text: text_cust,
                    confirmButtonText: 'Aceptar',
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-success btn-fill'
                })
            },
            onChangeImage(e, section) {
                //console.log('event', e.target.files)
                if(e.target.files.length){
                    const image = e.target.files[0];
                    //console.log(image, 'image');
                    const reader = new FileReader();
                    //this.room.image[section].img = image
                    reader.readAsDataURL(image);
                    reader.onload = e =>{
                        this.room.image[section].img = e.target.result;
                    };
                   
                }
            },


            addFile(file, fileList){
                const fileLanding = file.file;
                //console.log('file', file);
                const reader = new FileReader();
                //this.room.image[section].img = image
                reader.readAsDataURL(fileLanding);
                reader.onload = e =>{
                    this.landing.file = [];
                    this.landing.file.push( {
                        _id: '',
                        name: fileLanding.name,
                        url: e.target.result,
                        onServer: false
                    });
                };
            },

            addImage(file, fileList){
                let type = 'image';
                this.addAttachment(file, fileList, type);               
            },
            addImageMovil(file, fileList){
                let type = 'image_movil';
                this.addAttachment(file, fileList, type);
            },

            addAttachment(file, fileList, typeIs) {
                const image = file.file;
                const reader = new FileReader();

                let widthRequired = 0;
                let heightRequired = 0;
                if (typeIs === 'image') {
                    widthRequired = 1100;
                    heightRequired = 400;
                }
                if (typeIs === 'image_movil') {
                    widthRequired = 330;
                    heightRequired = 400;
                }

                reader.readAsDataURL(image);
                
                reader.onload = (e) => {
                    // Crear una nueva instancia de Image
                    const img = new Image();

                    // Configurar el evento onload para garantizar que las dimensiones estén disponibles
                    img.onload = () => {
                        // Obtener el ancho y alto de la imagen
                        const width = img.width;
                        const height = img.height;

                        // Comprobar si las dimensiones de la imagen son aceptables
                        if (width !== widthRequired || height !== heightRequired) {
                            swal({
                                type: 'warning',
                                text: 'Las dimensiones de la imagen no son correctas, deben ser de '+widthRequired+'px / '+heightRequired+'px',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            return;
                        }
                        if(img.size / 1024 / 1024 > 1){
                            swal({
                                type: 'warning',
                                text: 'El tamaño de la imagen no puede exceder 1 MB',
                                confirmButtonClass: 'btn btn-success btn-fill',
                                buttonsStyling: false
                            })
                            return;
                        }
                        
                            console.clear();
                            console.log('prueba', 'background: #222; color: #bada55');
                            console.table('file', width, height);

                        this.landing[typeIs] = [];
                        this.landing[typeIs].push({
                            _id: '',
                            name: image.name,
                            url: e.target.result,
                            onServer: false,
                            width: width,
                            height: height,
                        });

                        if (typeIs === 'image') {
                            this.dialogImageUrl = e.target.result;
                            this.dialogVisible = true;
                        }

                        if (typeIs === 'image_movil') {
                            this.dialogImageMovilUrl = e.target.result;
                            this.dialogMovilVisible = true;
                        }
                    };

                    // Establecer la fuente de la imagen para desencadenar el evento onload
                    img.src = e.target.result;
                };
            },

            handleRemoveMovil(file, fileList) {

                this.landing.image_movil = [];
            },
            handleRemove(file, fileList) {

                this.landing.file = [];
            },
            handlePictureCardPreview(file) {
                /*this.dialogImageUrl = file.url;
                this.dialogVisible = true;*/
            },
            submitUpload() {
                //console.log( this.$refs.upload);
            },
            onBeforeUpload(file)
            {
                const isIMAGE = file.type === 'image/jpeg'||'image/gif'||'image/png';
                const isLt1M = file.size / 1024 / 1024 < 2;

                if (!isIMAGE) {
                    swal({
                        type: 'warning',
                        text: 'El archivo de carga solo puede estar en formato de imagen',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }
                if (!isLt1M) {
                    swal({
                        type: 'warning',
                        text: 'El tamaño de la imagen no puede exceder 2 MB',
                        confirmButtonClass: 'btn btn-success btn-fill',
                        buttonsStyling: false
                    })
                    return isLt1M;
                }

                //this.objAddItem(this.listImgs, file);
            },
             sendForm(action){

                this.validate(action);


            },

            async updateItem(){

                this.initLoading('Actualizando')
                let params =  this.landing;
                params.to = params.vigency[1];
                params.from = params.vigency[0];
                let arrays_rateplans = this.info_page.rateplans;
                arrays_rateplans = arrays_rateplans.filter(element => element.checked);
                params.rateplans = arrays_rateplans.map(s=>s._id)
                if(params.file.length && params.file[0].onServer === false){
                    let file= this.processImageFile(params.file[0].url, params.file[0].name)
                    let extFile = file.type;
                    extFile = extFile.replace('image/', '');
                    extFile = extFile.replace('application/', '');
                    let fileData = new FormData();
                    fileData.append('file', file, file.name);
                    fileData.append('property', this.selected_property.name);
                    fileData.append('type', 'file');
                    let responseFile =  await landingService.createFileImage(fileData, '-file');
                    params.file = responseFile.data.data.file;
                    let responseRequest =  await landingService.deleteFileImage(this.info_page.original_info.file);
                    //params.file = responseFile.data.data.file;
                    //console.log('responseFile', responseFile)
                }else if(params.file.length && params.file[0].onServer){
                    params.file = params.file[0].url;
                }else{

                    if(this.info_page.original_info.file){
                        let responseRequest =  await landingService.deleteFileImage(this.info_page.original_info.file);
                    }
                    params.file = null;
                }
                if(params.image_movil.length && params.image_movil[0].onServer === false){
                    let file= this.processImageFile(params.image_movil[0].url, params.image_movil[0].name)
                    let extFile = file.type;
                    extFile = extFile.replace('image/', '');
                    extFile = extFile.replace('application/', '');
                    let fileData = new FormData();
                    fileData.append('file', file, file.name);
                    fileData.append('property', this.selected_property.name);
                    fileData.append('type', 'banner_movil');
                    let responseFile =  await landingService.createFileImage(fileData, '-file');
                    params.image_movil = responseFile.data.data.file;
                    if(this.info_page.original_info.image_movil){
                        let responseRequest =  await landingService.deleteFileImage(this.info_page.original_info.image_movil);
                    }
                    //params.file = responseFile.data.data.file;
                    //console.log('responseFile', responseFile)
                }else if(params.image_movil.length && params.image_movil[0].onServer){
                    params.image_movil = params.image_movil[0].url;
                }else{

                    if(this.info_page.original_info.image_movil){
                        let responseRequest =  await landingService.deleteFileImage(this.info_page.original_info.image_movil);
                    }
                    
                    params.image_movil = null;
                }


                if(params.image[0].onServer === false){
                    let image = this.processImageFile(params.image[0].url, params.image[0].name)
                    let ext = image.type.replace('image/', '');
                    let formData = new FormData();
                    formData.append('file', image, image.name);
                    formData.append('property', this.selected_property.name);
                    formData.append('type', 'banner');
                    let responseImage =  await landingService.createFileImage(formData, '-file');
                    params.image = responseImage.data.data.file;
                    try{
                        let responseRequest =  await landingService.deleteFileImage(this.info_page.original_info.image);
                    }catch (e) {
                        console.log('error', e)
                    }
                }else{
                    params.image = params.image[0].url;
                }
                landingService.update(params).then( async response=>{
                    response = response.data.data.landing;
                    let changes = logFunctions.cheackProp(this.info_page.original_info, response, this.info_page.section_name)
                    if(changes.length){
                        await this.createLog(this.landing._id, 1, changes);
                    }

                    this.showSwal(this.$t('landing.edit.msg'));
                    this.closeLoading();
                });

            },


            async saveItem(){

                this.initLoading('Guardando')
                let params =  this.landing;
                params.to = params.vigency[1];
                params.from = params.vigency[0];
                //console.log(params)

                let arrays_rateplans = this.info_page.rateplans;
                arrays_rateplans = arrays_rateplans.filter(element => element.checked);
                params.rateplans = arrays_rateplans.map(s=>s._id)
                if(params.file.length){
                    console.log(params.file);
                    let file= this.processImageFile(params.file[0].url, params.file[0].name)
                    //console.log(file, 'File')
                    let extFile = file.type;
                    extFile = extFile.replace('image/', '');
                    extFile = extFile.replace('application/', '');
                    //console.log(file, 'extFile', extFile)

                    let fileData = new FormData();
                    fileData.append('file', file, file.name);
                    fileData.append('property', this.selected_property.name);
                    fileData.append('type', 'file');
                    let responseFile =  await landingService.createFileImage(fileData, '-file');
                    params.file = responseFile.data.data.file;
                    //console.log('responseFile', responseFile)
                }else{
                    params.file = null;
                }
                if(params.image_movil.length){
                    let image_movil = this.processImageFile(params.image_movil[0].url, params.image_movil[0].name)
                    let ext = image_movil.type.replace('image/', '');
                    let formData = new FormData();
                    formData.append('file', image_movil, image_movil.name);
                    formData.append('property', this.selected_property.name);
                    formData.append('type', 'banner_movil');
                    let responseImage =  await landingService.createFileImage(formData, '-file');
                    params.image_movil = responseImage.data.data.file;

                }else{
                    params.image_movil = null;
                }


                //Esto ya estaba comentado
                let image = this.processImageFile(params.image[0].url, params.image[0].name)
                let ext = image.type.replace('image/', '');
                let formData = new FormData();
                formData.append('file', image, image.name);
                formData.append('property', this.selected_property.name);
                formData.append('type', 'banner');
                let responseImage =  await landingService.createFileImage(formData, '-file');
                params.image = responseImage.data.data.file;
                landingService.create(params).then(async response=>{
                    //console.log('landing Data response', response);
                    let new_landing = response.data.data.landing;
                    await this.createLog(new_landing._id, 0)
                    this.showSwal(this.$t('landing.new.msg'));
                    this.closeLoading();
                });
            },


            /*------------------------------Validaciones------------------------*/
            getError(fieldName) {
                return this.errors.first(fieldName);
            },
            validate(action) {
                // console.log('action?', action, this.customValidity())
                this.customValidity();
                this.$validator.validateAll().then(isValid => {
                    if (isValid) {
                        if (this.customValidity()) {
                            if (action === 'add') {
                                this.saveItem();
                            } else {
                                this.updateItem();
                            }
                        }

                    }
                })
            },

            customValidity() {
                let error_response = true;
                let landing = this.landing;
                let errores = this.errores;

                errores.content = false;
                errores.image = false;
                errores.vigency = false;
                errores.rateplans = false;

                if(rateplanFuntions.validadorCustom([landing.content.find(item=>item.lang==='es').text], 'string')){
                    error_response = false;
                    errores.content = true;
                }

                if(landing.image.length === 0){
                    error_response = false;
                    errores.image = true;
                }
                if (landing.vigency === null || landing.vigency.length < 2 || landing.vigency[0] === null || landing.vigency[1] === null) {
                    error_response = false;
                    errores.vigency = true;
                }

                if (!this.info_page.rateplans.some(item => item.checked === true)) {
                    error_response = false;
                    errores.rateplans = true;
                }

                return error_response;
            },


            processImageFile(data, filename) {

                let arr = data.split(',');
                let mime = arr[0].match(/:(.*?);/)[1];
                let bstr = atob(arr[1]);
                let n = bstr.length;
                const u8arr = new Uint8Array(n);
                console.log('data', mime, bstr);

                while (n) {
                    u8arr[n-1] = bstr.charCodeAt(n-1);
                    n -= 1;
                }

                return new File([u8arr], filename, { type: mime })
            },

        },
        mounted() {
            this.changeProperty()
            //this.$refs.topProgress.start();
        }
    }
</script>
<style lang="scss">
    .bg-transparend-modal {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        z-index: 10;
        background: rgb(2,0,36);
        background: linear-gradient(270deg, rgba(2,0,36,0) 0%, rgba(255,255,255,0.8995973389355743) 150%);
    }
    .minus-aggreates{
        position: relative;
        top: 10px;
        display: inline-block;
        vertical-align: top;
    }
    .custom-error {
        label.text-danger {
            text-transform: none;
            white-space: nowrap;
            font-size: 80%;
        }
        &.mbis-0 {
            .form-group {
                margin-bottom: 0;
            }
        }
    }
    .box-inventory.landings {
        .card .avatar{
            border-radius: 0;
        }
        .bootstrap-switch {
            position: relative;
            top: 15px;
            float: right;
        }
        .subtitle {
            margin-bottom: 5px;
            font-weight: 600 !important;
            font-size: 16px;
            text-transform: none;
            color: #0e0e0e;

        }

        .form-updates, .form-dates{
            position: relative;
            .resumen-item{
                label, span{
                    font-size: 14px;
                }
            }
        }
        span.fllw {
            display: inline-block;
            width: 100%;
        }

        .fw {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            text-transform: none;
            color: #0e0e0e;
        }
        span.btn-link {
            margin-left: 0;
        }
        .group-edit {
            position: absolute;
            right: 10px;
            top: 10px;
            .type-text {
                margin-left: 10px;
            }

            .type-button {
                width: 45px;
                height: 45px;
                color: #ffffff;
                text-align: center;
                border-radius: 100px;
                display: inline-block;
                cursor: pointer;
                i {

                    font-size: 25px;
                    line-height: 45px;
                    left: -3px;
                    position: relative;
                }
            }
            .type-text {
                height: 45px;
                line-height: 45px;
                cursor: pointer;
            }
        }
        .group-item {
            &.title{
                label{
                    width: 200px;
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    color: #0e0e0e;
                }
            }

            .item-7 {
                width: 10%;
                display: inline-block;
                margin-left: 1%;
                position: relative;
                .fa {
                    position: absolute;
                    top: 37px;
                    bottom: 0;
                    margin: auto;
                    right: 5px;
                }
                span{
                    font-size: 14px;
                }
            }

            .input-default {
                width: 150px;
                display: inline-block;
                color: #0e0e0e;
                margin-bottom: 7px;
            }

            span.btn-link {
                color: #1D62F0;
                display: inline-block;
                font-size: 14px;

            }
            .select-default {
                display: inline-block;
                width: 150px;
                margin-bottom: 7px;
            }
            .large-size{
                width: 200px;

            }

        }

    }

    .line-complete{
        span{
            display: inline-block;
            color: #bf9000;
            font-size: 14px;
            width: auto;
            margin-left: 10px;
        }
        .s-icon {
            font-size: 20px;
            color: #bf9000;
            float: left;
        }
    }
    .box-inventory {
        border: 0;
        .form-send {
            .s-icon {
                font-size: 20px;
                color: #bf9000;
            }
            label {
                display: inline-block;
                color: #bf9000;
                font-size: 14px;
                width: auto;
                margin-left: 10px;
            }
            .box-button {
                button {
                    width: calc(33.3% - 30px);
                    margin-right: 15px;
                }
            }

        }
        .is_title {
            font-size: 16px;
            margin: 0 0 15px;
        }
        .subtitle {
            font-weight: 600;
            margin-bottom: 25px;
        }

        .box-button {
            //padding-left: 10px;
            .add-item {
                width: 100%;
                height: 40px;
                color: #fff;
                background-color: #222222;
                border: 2px solid #000;
                &.inactive {
                    opacity: .2;
                    cursor: default;
                }
            }
        }

        .form-dates {

            .box-dates {
                .form-check {
                    display: inline-block;
                    margin-right: 20px;
                }
            }
            .dates-manually {
                width: 100%;
                .box-calendar {
                    width: 100%;
                }
                .box-legends {
                    width: 100%;
                    padding: 15px 0;
                    display: inline-block;
                    label {
                        width: 30%;
                        float: left;
                        display: inline-block;
                        margin-bottom: 0;
                        &.color-blue {
                            color: #3B4FBF;
                        }
                        &.right-t {
                            text-align: right;
                            width: 40%;
                        }
                        .example-picker {
                            display: inline-block;
                            height: 16px;
                            width: 16px;
                            background: #3B4FBF;
                            margin-right: 7px;
                            border-radius: 15px;
                            &.blocks {
                                background-color: #fff;
                                border: 1px solid;
                            }
                        }
                    }

                }
            }
        }

        .w-border {
            border: 1px solid #bebebe;
            padding: 10px 25px;
            margin-bottom: 15px;
        }
        .form-preview {
            position: relative;
            .l-title {
                font-weight: 600;
            }
            .text-normal {
                font-size: 14px;
            }
            .brl {
                display: inline-block;
                width: 100%;
            }
            .a-right {
                position: absolute;
                right: 50px;
                top: 0;
                bottom: 0;
                left: auto;
                margin: auto;
            }

            .type-button {
                right: 120px;
            }
            .type-text {
                height: 45px;
                line-height: 45px;
            }
            .p-contain {
                width: calc(100% - 40%);
                position: relative;
                display: inline-block;
                border-bottom: 1px solid;
                margin-bottom: 5px;
                .s-left {
                    display: inline-block;
                    width: 50%;
                }
                .s-right {
                    text-align: right;
                    display: inline-block;
                    width: 50%;
                }
            }

        }

        .box-top {
            padding: 25px;
            border: 2px solid #eeeeee;
            margin-bottom: 15px;
        }
        label {
            color: #000;
            width: 100%;
            display: inline-block;
            font-size: 14px;
            text-transform: unset;
            span {
                font-weight: 400;
                margin-left: 5px;
            }
            span.icon-cont {
                margin-left: 0;
            }

        }
        h6 {
            margin-bottom: 15px;
            color: #085394;
            font-size: 18px;
            text-transform: unset;
        }
        .box-calendar {
            .item-month {

                .calendar-title {
                    font-size: 17px;
                    text-align: center;
                    font-weight: 600;
                    text-transform: uppercase;
                    margin-bottom: 5px;
                    //width: 60%;
                    &.w-80 {
                        width: 60%;
                    }
                    &.ml-20 {
                        margin-left: 20%;
                    }
                }
                .sp-icon {
                    width: 20%;
                    text-align: center;
                    &.n-visible {
                        visibility: hidden;
                    }
                }
                display: inline-block;
                width: 30%;
                float: left;
                &:first-child {
                    margin-right: 5%;
                }
                &:last-child {
                    margin-left: 5%;
                }
                .line-week {
                    width: 100%;
                    display: inline-block;
                    &.lw-head {
                        margin-bottom: 0;
                        font-weight: 600;
                    }
                    .item-7 {
                        cursor: pointer;
                        display: inline-block;
                        width: calc(100% / 7);
                        text-align: center;
                        height: 25px;
                        line-height: 25px;
                        float: left;
                        .select-day {
                            background: #3B4FBF;
                            width: 25px;
                            display: inline-block;
                            border-radius: 100px;
                            color: #fff;
                        }


                        .block-day {
                            background: #E1D8E9;
                            width: 100%;
                            display: inline-block;
                            color: #000;
                            cursor: default;
                        }
                    }
                }
            }
        }

        .box-head, .box-rate, .box-restrict {
            width: 100%;
            display: inline-block;
        }
        .box-head {
            margin-top: 15px;
            label {
                font-size: 16px;
                margin-bottom: 25px;
            }
        }
        .box-item {
            display: inline-block;
        }

        .form-check .form-check-label {
            text-transform: none;
        }
    }
    .ordF{
        order: 1;
    }
    .ordL{
        order: 2;
    }
    .mt-3{
        margin-top: 1rem;
    }
    .input-default.has-error input, .el-date-editor.has-error, .input-default.has-error .select-default.el-select .el-input input, .input-default.has-error textarea, .has-error .el-range-editor{
        border-color: #ff4500 !important;
        color: #ff4500!important;;
    }
    label.year-header{
        font-size: 22px;
        font-weight: 600;
    }
    .el-upload{
        width: 100%;
        .el-upload-dragger{
            width: 100%;
            img{
                border-radius: 0;
                width: 100%;
            }
        }
    }
    .el-upload-list__item-name {
        padding-left: 20px;
    }
    .box-colors{
        .ul-item.checked{
            line-height: 30px;
        }
        .bx-color {
            
            width: 100%;
            display: inline-block;
            border: 1px solid #E3E3E3;
            margin-bottom: 0.5rem;
            background: #ebebeb;
            border-radius: 5px;
            position: relative;
            input{
                background: #fff;
                border-radius: 5px;
                border: 0;
                width: 100%;
                position: relative;
                top: 0;
                padding-left: 7px;
                height: 38px;
                width: 100%;
            }
        }
        .color-picker-component{
            .current-color {
                display: inline-block;
                width: 30px;
                height: 100%;
                background-color: #000;
                cursor: pointer;
                margin: 0;
                top: 0;
                border-radius: 0 5px 5px 0;
                position: absolute;
                right: 0;
            }
            .ipt-border {
                width: 100%;
                border: 0;
                margin: 0;
                border-right: 2px solid;
                float: left;
                display: inline-block;
                height: 28px;
            }
        }

    }



    .color-picker-component{

        .picker {
            position: absolute;
            left: 10px;
            top: 30px;
            z-index: 10;
            margin-bottom: 50px;
        }
    }
    .bx-color{
        width: auto;
        display: inline-block;
    }
    .el-upload .el-upload-dragger {
        width: 100%;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        max-width: 1100px;
    }
    .el-upload .el-upload-dragger img {
        width: 100%;
    }
    .upload-demo.upload-movil{
        .el-upload .el-upload-dragger {
            width: 100%;
            max-width: 330px;
        }
    }

</style>
